module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#00FFFFFF","icon":"src/images/favicon-1.png","icons":[{"src":"src/images/favicon.png","sizes":"512x512","type":"image/png"},{"src":"src/images/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"src/images/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/images/favicon-16x16.png","sizes":"16x16","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8a154d15f62a701aadb82990f18a27c6"},
    },{
      plugin: require('../node_modules/@mkitio/gatsby-theme-password-protect/gatsby-browser.js'),
      options: {"plugins":[],"password":"g1uJ8s*AuR8!i9tby"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
